import React, { Suspense, lazy, useState } from 'react'
import { Button, H1 } from '@phoenix/all'
import PropTypes from 'prop-types'

const RequestAccessDialog = lazy(() =>
  System.import('@wf-mfe/dialogs').then((dialogsMFE) => {
    // React Lazy expects the component to be the Default export so we're making it look like a default export for React.lazy
    return { default: dialogsMFE.RequestAccessDialog }
  })
)

import { useLocalization, Localization } from './BoundLocalization.js'

export default function NoAccess({
  requestAccess,
  objectMessageKey,
  message,
  match,
  history,
}) {
  const [requestingAccess, setRequestingAccess] = useState(false)
  const [objectType] = useLocalization(objectMessageKey)
  const [thisMsg] = useLocalization('this')

  return (
    <div className="pt-8 flex flex-col items-center flex-1">
      {message ? (
        <H1 className="mr-8 ml-8">{message}</H1>
      ) : (
        <H1 className="mr-8 ml-8">
          <Localization
            messageKey="exception.noaccess.headline"
            args={`${thisMsg} ${objectType}`}
          />
        </H1>
      )}
      {requestAccess && (
        <Button onClick={() => setRequestingAccess(true)}>
          <Localization messageKey="request.access" />
        </Button>
      )}
      {requestingAccess && (
        <Suspense fallback={null}>
          <RequestAccessDialog
            match={match}
            history={history}
            onClose={() => {
              setRequestingAccess(false)
            }}
          />
        </Suspense>
      )}
    </div>
  )
}

NoAccess.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  message: PropTypes.string,
  objectMessageKey: PropTypes.string.isRequired,
  requestAccess: PropTypes.bool,
}
