import React from 'react'
import { H1, H2 } from '@phoenix/all'
import PropTypes from 'prop-types'

import { Localization, useLocalization } from './BoundLocalization.js'

export default function NotFound({ objectMessageKey }) {
  const [objectType] = useLocalization(objectMessageKey)

  return (
    <div
      data-testid="not-found"
      className="pt-8 flex flex-col items-center flex-1"
    >
      <H1 className="mr-8 ml-8">
        <Localization
          messageKey="deleted.object.not.exist"
          args={[objectType, '']}
        />
      </H1>
      <H2 className="mr-8 ml-8">
        <Localization messageKey="exception.objectnotfound.message" />
      </H2>
    </div>
  )
}

NotFound.propTypes = {
  objectMessageKey: PropTypes.string.isRequired,
}
