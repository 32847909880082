import React from 'react'
import reactDOM from 'react-dom'

// import Parcel from 'single-spa-react/parcel'
import {
  getNamedNode,
  waitForDomNode,
} from '../single-spa-slots/single-spa-slots.js'
// import { mountRootParcel } from 'single-spa'

/**
 * @typedef SlotOptions
 * @param {JSX.Element} [children] - children components to render in the slot
 * @param {string} [mfeName] - name of the mfe using the slot. Ex. `@wf-mfe/quicksilver`, used for logging and workfront-inspector overlay
 * @param {object} [config]
 */

/**
 * @param {Object} props
 * @param {string} props.name - name of slot type
 * @param {object} [props.config]
 * @param {JSX.Element} [props.children] - children components to render in the slot
 * @param {string} [props.mfeName] - name of the mfe using the slot. Ex. `@wf-mfe/quicksilver`, used for logging and workfront-inspector overlay
 * @returns {JSX.Element}
 */
export function Slot({
  name,
  config = {},
  children,
  mfeName = 'not-quicksilver' /*, ...other */,
  timeout,
}) {
  const [domNode, setDomNode] = React.useState(getNamedNode(name))
  const mfeNameIdentifier = mfeName.replace('@', '').replace('/', '_') || ''

  const isParcel = !!config.bootstrap && !!config.mount && !!config.unmount

  React.useLayoutEffect(() => {
    if (domNode && mfeNameIdentifier !== 'not-quicksilver') {
      domNode.classList.add(mfeNameIdentifier)
    }
    return () => {
      if (domNode) {
        domNode.classList.remove(mfeNameIdentifier)
      }
    }
  }, [domNode, mfeNameIdentifier])

  React.useEffect(() => {
    if (!domNode) {
      const wait = waitForDomNode(name, timeout)
      wait.then(setDomNode).catch(console.error)

      return wait.stop
    }
  }, [domNode, name, timeout])

  if (isParcel) {
    console.error(`Parcel isn't working yet`)
    return null
    // return (
    //   <Parcel
    //     appendTo={domNode}
    //     // mountParcel={mountRootParcel}
    //     config={config}
    //     {...other}
    //   ></Parcel>
    // )
  } else {
    if (domNode) {
      return reactDOM.createPortal(children, domNode)
    } else {
      return null
    }
  }
}

function SlotExport(name, props) {
  const { children, ...other } = props
  return (
    <Slot {...other} name={name}>
      {children}
    </Slot>
  )
}

/**
 * @param {SlotOptions} props
 * @returns {JSX.Element}
 */
export const GlobalNavSecondarySlot = SlotExport.bind(
  this,
  'global-nav-secondary'
)
/**
 * @param {SlotOptions} props
 * @returns {JSX.Element}
 */
export const SecondaryNavSlot = SlotExport.bind(this, 'secondary-nav')
/**
 * @param {SlotOptions} props
 * @returns {JSX.Element}
 */
export const PageHeaderSlot = SlotExport.bind(this, 'page-header')
/**
 * @param {SlotOptions} props
 * @returns {JSX.Element}
 */
export const PageToolbarSlot = SlotExport.bind(this, 'page-toolbar')
/**
 * @param {SlotOptions} props
 * @returns {JSX.Element}
 */
export const PageSideBarSlot = SlotExport.bind(this, 'page-sidebar')
/**
 * @param {SlotOptions} props
 * @returns {JSX.Element}
 */
export const PageContentSlot = SlotExport.bind(this, 'page-content')
/**
 * @param {SlotOptions} props
 * @returns {JSX.Element}
 */
export const PageSiderailSlot = SlotExport.bind(this, 'page-siderail')
