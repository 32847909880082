import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom-v5-compat'

import { customPerformanceMetrics } from '@wf-mfe/logger'

import { ShimmedComponent } from './ShimmedComponent'

const { useCustomPerformanceMetrics } = customPerformanceMetrics

/**
 * @param {Object} props
 * @param {string} [props.basePathname] - The base pathname that is used for caching at the shim layer
 * @param {any} [props.children] - a React component (the route's children; use this or component)
 * @param {any} [props.component] - a React component (the route's component; use this or children; will be passed react-router Route props for the sub-route)
 * @param {any} [props.defaultTileParameters] - the base tile parameters that are merged with any `tile` prop to become the parameters for a "Kamino route"; if you pass a function it receives the react-router props and should return the object used as the base parameters
 * @param {object} [props.dialogTilePaths] - a map of tile ids to a route; value for an entry is either a String or a Function that will be passed the tile parameters from Kamino when it tries to open a dialog, e.g. monolith is opening report builder dialog, do a route transition and render the builder in a new route and not as a full screen dialog
 * @param {any} [props.dialogs] - a map of dialogs the monolith is asking Quicksilver to render; use this when you want to open a new React component instead of a KaminoShim at a new route, e.g. document central preview "breaks out of" the shim and renders a gallery in Quicksilver in a dialog; the function will be passed internal Page state such as `obj`
 * @param {object} [props.eventBus] - a map of Kamino.EventBus event names; when provided Quicksilver will subscribe to these events and run the provided callback function for each event name/key as the monolith fires those events from the shim
 * @param {boolean} [props.fullBleed] - renders the component without left/right padding if `fullBleed` is `true`
 * @param {boolean} [props.isInActiveTab]
 * @param {boolean} [props.isList] - you would use if your tile loads the filters/views/groupings in the list header; this will load those modals as pages instead
 * @param {boolean} [props.legacyStyles] - enabling this will turn monolith styles back on; the `styles` prop can be used to override any styling in the iframe
 * @param {any} [props.legacyStylesheets] - specify a tile or 2-tile combination, a.k.a. dialog styling, to load a stylesheet from `/static/prod/css/tiles/\${this.props.legacyStylesheet}.css`
 * @param {object} [props.parameters] - * a map of tile parameters to pass to Kamino.TileLoader via the shim. Note: setting a `tile` or `parameters` prop forces you into the KaminoShim as the component; `children` and `component` props will be ignored in that case
 * @param {string} [props.path]
 * @param {any} [props.permissions] - should match up with our permissions:actions from the monolith (e.g. VIEW_FINANCE or EDIT)
 * @param {string} [props.styles] - styles to pass into the shim's iframe
 * @param {any} [props.tabIcon] - a Phoenix Design System icon
 * @param {any} [props.tabLabel] - a React component, typically a `<Translate>`
 * @param {string} [props.tabPath]
 * @param {string} [props.tile] - * becomes parameters for the shim which are passed to Kamino.TileLoader (plus optional Page defaultTileParameters). Note: setting a `tile` or `parameters` prop forces you into the KaminoShim as the component; `children` and `component` props will be ignored in that case
 * @returns {JSX.Element}
 */
export function PageContent(props) {
  const {
    fullBleed,
    isInActiveTab,
    parameters,
    tile,
    skipDefaultPerformanceMetric,
    basePathname,
  } = props

  const location = useLocation()
  const propsMatch = props.match

  // the shim system relies on the props.match which is from the parent
  // later on we need to move to the hook
  const match = propsMatch
  useShimReloader(location, isInActiveTab)

  const isShim = tile !== undefined || parameters !== undefined

  const refForPerformanceMeasurements = useCustomPerformanceMetrics(isShim)

  if (isShim) {
    return (
      <ShimmedComponentWithRouteProps
        {...props}
        basePathname={basePathname}
        match={match}
      />
    )
  }

  const containerRef = skipDefaultPerformanceMetric
    ? undefined
    : refForPerformanceMeasurements

  const {
    component: Component,
    children,
    ...allPropsExceptComponentAndChildren
  } = props

  let child = null

  if (children) {
    child = React.cloneElement(children, allPropsExceptComponentAndChildren)
  } else if (Component) {
    child = <Component {...allPropsExceptComponentAndChildren} />
  }

  return (
    <div
      ref={containerRef}
      data-testid="page-content"
      id="responsive-content"
      className={`responsive-content flex flex-col flex-1 h-full w-full overflow-auto relative z-0 ${
        fullBleed ? '' : 'p-4'
      }`}
    >
      {child}
    </div>
  )
}

function ShimmedComponentWithRouteProps(props) {
  const { defaultTileParameters, parameters, tile, basePathname, match } = props
  const navigate = useNavigate()
  const location = useLocation()

  const shimParameters = getShimParameters(
    defaultTileParameters,
    parameters,
    tile,
    { navigate, location, match }
  )

  return (
    <ShimmedComponent
      basePathname={basePathname || match?.url}
      parameters={shimParameters}
      {...props}
    />
  )
}

// TODO shim renderer could take this code
function useShimReloader(location, isInActiveTab) {
  useEffect(() => {
    const { reloadParameters, shouldReloadMainFrame } = location.state || {}
    const shouldReload =
      (isInActiveTab && shouldReloadMainFrame) || reloadParameters

    if (shouldReload) {
      System.import('@wf-mfe/kamino').then((kaminoMFE) =>
        kaminoMFE.postMessage({
          type: 'reloadTile',
          reloadParameters,
        })
      )
    }
  }, [isInActiveTab, location.state])
}

function getShimParameters(
  defaultTileParameters,
  parameters,
  tile,
  routeProps
) {
  if (parameters) {
    return parameters
  }

  if (tile) {
    const isFunction = typeof defaultTileParameters === 'function'
    const defaultTileParams = isFunction
      ? defaultTileParameters(routeProps)
      : defaultTileParameters

    return {
      content: tile,
      ...defaultTileParams,
    }
  }

  return {}
}

PageContent.propTypes = {
  /** a React component (the route's children; use this or component) */
  children: PropTypes.node,
  /** a React component (the route's component; use this or children; will be passed react-router Route props for the sub-route) */
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /** the base tile parameters that are merged with any `tile` prop to become the parameters for a "Kamino route"; if you pass a function it receives the react-router props and should return the object used as the base parameters */
  defaultTileParameters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  /** a map of tile ids to a route; value for an entry is either a String or a Function that will be passed the tile parameters from Kamino when it tries to open a dialog, e.g. monolith is opening report builder dialog, do a route transition and render the builder in a new route and not as a full screen dialog */
  dialogTilePaths: PropTypes.object,
  /** a map of dialogs the monolith is asking Quicksilver to render; use this when you want to open a new React component instead of a KaminoShim at a new route, e.g. document central preview "breaks out of" the shim and renders a gallery in Quicksilver in a dialog; the function will be passed internal Page state such as `obj` */
  dialogs: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** a map of Kamino.EventBus event names; when provided Quicksilver will subscribe to these events and run the provided callback function for each event name/key as the monolith fires those events from the shim */
  eventBus: PropTypes.object,
  /** renders the component without left/right padding if `fullBleed` is `true` */
  fullBleed: PropTypes.bool,
  isInActiveTab: PropTypes.bool,
  /** you would use if your tile loads the filters/views/groupings in the list header; this will load those modals as pages instead */
  isList: PropTypes.bool,
  /** enabling this will turn monolith styles back on; the `styles` prop can be used to override any styling in the iframe */
  legacyStyles: PropTypes.bool,
  /** specify a tile or 2-tile combination, a.k.a. dialog styling, to load a stylesheet from `/static/prod/css/tiles/\${this.props.legacyStylesheet}.css` */
  legacyStylesheets: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * a map of tile parameters to pass to Kamino.TileLoader via the shim
   * Note: setting a `tile` or `parameters` prop forces you into the KaminoShim as the component; `children` and `component` props will be ignored in that case
   */
  parameters: PropTypes.object,
  path: PropTypes.string,
  /** should match up with our permissions:actions from the monolith (e.g. VIEW_FINANCE or EDIT) */
  permissions: PropTypes.string,
  /** styles to pass into the shim's iframe */
  styles: PropTypes.string,
  /** a Phoenix Design System icon */
  tabIcon: PropTypes.node,
  /** a React component, typically a `<Translate>` */
  tabLabel: PropTypes.node,
  tabPath: PropTypes.string,
  /**
   * becomes parameters for the shim which are passed to Kamino.TileLoader (plus optional Page defaultTileParameters)
   * Note: setting a `tile` or `parameters` prop forces you into the KaminoShim as the component; `children` and `component` props will be ignored in that case
   */
  tile: PropTypes.string,
}

PageContent.displayName = 'PageContent'
