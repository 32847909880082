import React, { useRef, useCallback, memo } from 'react'
import PropTypes from 'prop-types'

function detailObjectChanged(parameters) {
  System.import('@wf-mfe/kamino').then((kaminoMFE) =>
    kaminoMFE.postMessage(
      {
        type: 'detail-object-changed',
        parameters,
      },
      'main-frame'
    )
  )
}

function updateCurrentTabSecondaryName(name) {
  document.title = name
}

export const HeaderEditingRouteObjectName = memo(
  function HeaderEditingRouteObjectName(props) {
    const { HeaderComponent, ...remainingProps } = props

    const _headerLoaded = useRef(false)
    const onHeaderLoaded = useCallback(() => {
      //secondary nav listens to this event and will call generateSeeMoreSectionIfNecessary
      window.dispatchEvent(new Event('resize'))

      // send qsHeaderReloaded event only if header was loaded and then reloaded
      if (_headerLoaded.current) {
        window.postMessage(
          {
            source: 'qsHeaderReloaded',
          },
          window.location.origin
        )
      } else {
        _headerLoaded.current = true
      }
    }, [])

    return (
      <HeaderComponent
        {...remainingProps}
        onSuccessfulReload={onHeaderLoaded}
        updateCurrentTabSecondaryName={updateCurrentTabSecondaryName}
        detailObjectChanged={detailObjectChanged}
      />
    )
  }
)

HeaderEditingRouteObjectName.propTypes = {
  HeaderComponent: PropTypes.any,
  routeParamsID: PropTypes.string,
}
