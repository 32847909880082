import React, { useEffect } from 'react'

import { ToastQueue } from '@react-spectrum/toast'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { Localization } from './BoundLocalization.js'

export function useGrantPermissionSuccess({ obj }) {
  const location = useLocation()
  const navigate = useNavigate()
  const search = location?.search
  const objName = obj?.name || obj?.displayName

  useEffect(() => {
    const urlParams = new URLSearchParams(search.replace(/^\?/, ''))
    if (urlParams.get('grantPermissionSuccess') === 'true' && objName) {
      ToastQueue.positive(
        <Localization messageKey="share.saved" args={[objName]} />
      )

      urlParams.delete('grantPermissionSuccess')
      navigate({ search: urlParams.toString() }, { replace: true })
    }
  }, [navigate, objName, search])
}
