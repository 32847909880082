import React, { lazy, Suspense } from 'react'

// This may have to stay a lazy import using System
// layout uses kamino, kamino uses layout (slots)
const ShimRenderer = lazy(() =>
  System.import('@wf-mfe/kamino').then((kaminoMFE) => {
    // React Lazy expects the component to be the Default export so we're making it look like a default export for React.lazy
    return { default: kaminoMFE.ShimRenderer }
  })
)

export function ShimmedComponent(props) {
  return (
    <Suspense fallback={null}>
      <ShimRenderer {...props} />
    </Suspense>
  )
}
